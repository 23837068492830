<template>
  <div class="d-inline-block">
    <span v-if="disabled" v-text="modelValue" />
    <v-menu v-else :close-on-content-click="false" width="300" @update:model-value="onMenu">
      <template v-slot:activator="{ props: activatorProps }">
        <span v-bind="activatorProps" v-text="modelValue" />
      </template>
      <qtm-text-field v-model="value" :data-test="inputDataTest" :maxlength="maxlength" />
    </v-menu>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  disabled?: boolean
  inputDataTest?: string
  maxlength?: number | string
  modelValue: string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  inputDataTest: undefined,
  maxlength: undefined,
  modelValue: '',
})
const emit = defineEmits(['update:model-value'])

const value = ref<string>(props.modelValue)

const onMenu = (open: boolean) => {
  if (!open && value.value && props.modelValue !== value.value) {
    emit('update:model-value', value.value)
  }
}
</script>
